@import '../../../../scss/_theme-bootstrap';

.country-chooser-formatter {
  bottom: 0;
  padding: 10px 10px 20px;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 9999;
  opacity: 0.9;
  @include breakpoint($landscape-up) {
    padding: 40px 10px 30px;
  }
  &__header {
    margin: 20px 23px 10px;
    @include main-heading;
    @include breakpoint($landscape-up) {
      margin: 20px 0 10px;
    }
  }
  &__links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
  }
  &__close {
    background-color: inherit;
    border: 0;
    cursor: pointer;
    position: absolute;
    top: 23%;
    transform: translate(-50%, -50%);
    #{$rdirection}: -5px;
    @include breakpoint($landscape-up) {
      top: 20%;
      #{$rdirection}: 0;
    }
    .icon {
      fill: $color-white;
      height: 15px;
      width: 15px;
      stroke-width: 30px;
      position: relative;
      #{$rdirection}: auto;
    }
  }
}
